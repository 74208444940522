import React from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import {calculateYearPeriod} from '../utils/utils'
import MetaTags from "../components/Blog/MetaTags";

export default function About() {
    const age = calculateYearPeriod(process.env.REACT_APP_MY_BIRTHDATE);
    const experienceAge = calculateYearPeriod(process.env.REACT_APP_START_WORKING_DATETIME);

    return (
        <>
            <MetaTags title={process.env.REACT_APP_PROJECT_NAME + " - About Me"} />

            <PerfectScrollbar>
                <section data-id="about-me" className="animated-section section-active">
                    <div className="page-title">
                        <h1>About <span>Me</span></h1>
                    </div>

                    <div className="section-content">
                        <div className="row">
                            <div className="col-xs-12 col-sm-7">
                                <p>I am a Python developer with over {experienceAge} years of experience in the web and
                                    application industry, proficient in frameworks such as Django and Flask. My profound
                                    interest in programming and developing intelligent applications led me to pursue a
                                    degree in Artificial Intelligence. This passion has fueled my involvement in
                                    numerous projects and collaborations with various companies, allowing me to gain
                                    extensive experience and expertise.</p>
                                <p>I thrive in Agile environments and have successfully managed multiple projects. My
                                    current focus is on utilizing AI to optimize processes and achieve superior
                                    outcomes, particularly in the fields of medicine and trading. My university thesis
                                    focused on the detection of epilepsy using hybrid methods, and I am presently
                                    engaged in a project aimed at predicting market movements.</p>
                                <p>Throughout my career, I have committed myself to learning multiple programming
                                    languages, deriving immense satisfaction from the learning process. Despite this,
                                    my primary focus remains on Python, driven by my strong passion for Artificial
                                    Intelligence.</p>
                            </div>

                            <div className="col-xs-12 col-sm-5">
                                <div className="info-list">
                                    <ul>
                                        <li>
                                            <span className="title">Age</span>
                                            <span className="value">{age}</span>
                                        </li>

                                        <li>
                                            <span className="title">Residence</span>
                                            <span className="value">IRAN</span>
                                        </li>

                                        <li>
                                            <span className="title">Address</span>
                                            <span className="value">Tehran</span>
                                        </li>

                                        <li>
                                            <span className="title">Email</span>
                                            <span className="value">
                                            <a href="mailto:imankarimi.mail@gmail.com">
                                                imankarimi.mail@gmail.com
                                            </a>
                                        </span>
                                        </li>

                                        <li>
                                            <span className="title">Phone</span>
                                            <span className="value">+98 937 597 9955</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="white-space-50"></div>

                        <div className="row">
                            <div className="col-xs-12 col-sm-12">
                                <div className="block-title">
                                    <h3>What <span>I Do</span></h3>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xs-12 col-sm-6">
                                <div className="col-inner">
                                    <div className="info-list-w-icon">
                                        <div className="info-block-w-icon">
                                            <div className="ci-icon">
                                                <i className="lnr lnr-laptop-phone"></i>
                                            </div>
                                            <div className="ci-text">
                                                <h4>Web and Application Development</h4>
                                                <p>Specialize in creating robust solutions using Python, with extensive
                                                    experience in frameworks such as Django and Flask.</p>
                                            </div>
                                        </div>
                                        <div className="info-block-w-icon">
                                            <div className="ci-icon">
                                                <i className="lnr lnr-magnifier"></i>
                                            </div>
                                            <div className="ci-text">
                                                <h4>Research and Development</h4>
                                                <p>Conduct advanced research, including projects like detecting epilepsy
                                                    through hybrid methods and predicting market movements.</p>
                                            </div>
                                        </div>
                                        <div className="info-block-w-icon">
                                            <div className="ci-icon">
                                                <i className="lnr lnr-license"></i>
                                            </div>
                                            <div className="ci-text">
                                                <h4>Agile Methodology</h4>
                                                <p>Thrive in Agile environments, promoting iterative development and
                                                    continuous improvement.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-6">
                                <div className="col-inner">
                                    <div className="info-list-w-icon">
                                        <div className="info-block-w-icon">
                                            <div className="ci-icon">
                                                <i className="lnr lnr-graduation-hat"></i>
                                            </div>
                                            <div className="ci-text">
                                                <h4>Artificial Intelligence Integration</h4>
                                                <p>Leverage AI to optimize processes and achieve superior results,
                                                    particularly in the fields of medicine and trading.</p>
                                            </div>
                                        </div>
                                        <div className="info-block-w-icon">
                                            <div className="ci-icon">
                                                <i className="lnr lnr-flag"></i>
                                            </div>
                                            <div className="ci-text">
                                                <h4>Project Management</h4>
                                                <p>Lead and manage various projects, ensuring efficient workflows and
                                                    successful outcomes.</p>
                                            </div>
                                        </div>
                                        <div className="info-block-w-icon">
                                            <div className="ci-icon">
                                                <i className="lnr lnr-rocket"></i>
                                            </div>
                                            <div className="ci-text">
                                                <h4>Continuous Learning</h4>
                                                <p>Dedicate myself to learning and adopting new programming languages,
                                                    with a primary focus on Python due to its significant role in
                                                    AI.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </PerfectScrollbar>
        </>
    );
}